import React from 'react'
import { GetStaticProps, NextPage } from 'next'
import { GlobalProps, withLayoutProps } from '../globals/layout'
import { Layout } from '../components/layout/layout'
import { Error } from '../templates/error'

export type InternalServerErrorPageProps = GlobalProps & {

}

const InternalServerErrorPage: NextPage<InternalServerErrorPageProps> = ({ ...layoutProps }) => {
  return (
    <Layout { ...layoutProps }>
      <Error statusCode={'500'} />
    </Layout>
  )
}

export default InternalServerErrorPage

export const getStaticProps: GetStaticProps = async () => {
  return withLayoutProps({
    path: '/',
  })
}
